// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import select2 from "select2";
import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels";

// require("./datatables")
Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

function initFilters() {
  var options = {
    autoclose: true,
    language: 'it',
    format: 'yyyy-mm-dd',
    todayBtn: 'linked',
    clearBtn: true,
    todayHighlight: true
  };
  $('#filters_start_date').datepicker(options);
  $('#filters_end_date').datepicker(options);
  // $('#filters_hospital').select2({
  //   theme: "bootstrap",
  // });
}

function initOspedale(){
  $('#filters_hospital').select2({
    placeholder: "Seleziona i presidi",
    theme: "bootstrap",
  });
  $('#daily_report_hospital_id').select2({
    placeholder: "Seleziona il presidio",
    theme: "bootstrap",
  });
}

function burger(){
  $('.fa-bars').on('click', function(){
    $('body').toggleClass('nav-mobile-open');
  });
}


function datePickerForReport(type, with_summary= false) {
  var options = {
    autoclose: true,
    language: 'it',
    format: 'yyyy-mm-dd',
    todayBtn: 'linked',
    clearBtn: true,
    todayHighlight: true
  };
  var dp = $('.' + type).datepicker(options);
  dp.datepicker('show');
  
  dp.on('hide', function(e) {
    var params_string = 'format=xlsx&filter=daily&with_summary='+with_summary+'&report_date='+e.date
    if(e.date == undefined) {
      return;
    }
    else {
      window.location.href = '/downloads?'+params_string
    }
  });
}

function downloadReport() {
  $('.daily_data').on('click', function(){
    datePickerForReport('daily_data', false);
  });
  $('.daily_report').on('click', function(){
    datePickerForReport('daily_report', true);
  });
}


$(document).ready(function(){
  burger();
  initOspedale();
  initFilters();
  downloadReport();
});